import React from "react"
import { PropTypes } from "prop-types"
import styled from "styled-components"
import tw from "tailwind.macro"

import IconList from "../IconList"

const TitleIconColumnsSection = styled.section`
  ${tw`bg-grey-lighter`}

  .section-inner-content {
    ${tw`max-w-xl mx-auto px-18 py-50 md:px-40`}
  }

  .icon-list-wrap {
    ${tw`pt-28 md:pt-33`}
  }

  .section-titles {
    max-width: 555px;
  }
`

const TitleIconColumns = ({
  subTitle,
  mainTitle,
  items,
  iconFieldName,
  textFieldName,
  fieldLink,
}) => {
  return (
    <TitleIconColumnsSection>
      <div className="section-inner-content">
        <div className="section-titles">
          {subTitle && (
            <h5 className="section-sub-title sm:section-sub-title-lg">
              {subTitle}
            </h5>
          )}
          {mainTitle && <h2 className="section-main-title md:section-main-title-md">{mainTitle}</h2>}
        </div>
        <div className="icon-list-wrap">
          {items && (
            <IconList
              horizontal={true}
              items={items}
              iconFieldName={iconFieldName}
              textFieldName={textFieldName}
              fieldLink={fieldLink}
            />
          )}
        </div>
      </div>
    </TitleIconColumnsSection>
  )
}

TitleIconColumns.propTypes = {
  subTitle: PropTypes.string,
  mainTitle: PropTypes.string,
}

export default TitleIconColumns
