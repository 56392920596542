import React from "react"
import { PropTypes } from "prop-types"
import styled from "styled-components"
import tw from "tailwind.macro"
import Link from "../Link"
import classNames from "classnames"
import Leafs from "../../images/3-leafs.svg"

const TwoColContentSection = styled.div`
  @media (min-width: 768px) {
    width: ${props => (props.fullWidthTab ? "100%" : "50%")};
  }

  @media (min-width: 1280px) {
    width: ${props => (props.fullWidthLarge ? "100%" : "50%")};
  }

  .content-wrap {
    ${tw`pt-12 xl:pt-24`}
  }

  .paragraph-content {
    ${tw`font-medium`}

    p {
      ${tw`mb-12 font-medium`}

      :last-child {
        ${tw`mb-0`}
      }
    }
  }

  .btn-wrapper {
    ${tw`mt-22 md:flex`}
  }

  .two-col-titles-xl {
    max-width: 500px;

    h5 {
      ${tw`font-fb text-xs uppercase mb-8 text-primary`}
      letter-spacing: 3.6px;

      :before {
        content: "";
        background-image: url(${Leafs});
        ${tw`bg-contain bg-no-repeat w-12 h-12 block mb-4`}
      }
    }

    h2 {
      ${tw`font-fb text-2xl mb-0`}
      line-height: 44px;
      letter-spacing: -0.64px;

      @media (min-width: 768px) {
        font-size: 32px;
      }
    }
  }
`

const TwoColContent = props => {
  return (
    <TwoColContentSection
      fullWidthTab={props.fullWidthTab}
      className={classNames(
        "two-col-content",
        props.rowReverse ? "md:pl-10 xl:pl-74" : "md:pr-10 xl:pr-74",
        props.className
      )}
    >
      <div className="two-col-titles-xl">
        {props.subTitle && <h5>{props.subTitle}</h5>}
        {props.mainTitle && (
          <h2 className="xs:h2-sm lg:h2-lg">{props.mainTitle}</h2>
        )}
      </div>

      <div className="content-wrap">
        <div
          className="paragraph-content"
          dangerouslySetInnerHTML={{ __html: props.textContent }}
        />
        {props.button && (
          <div className="btn-wrapper">
            <Link
              className="btn-secondary btn-secondary-min-w btn-sm"
              to={props.button.url}
              target={props.button?.target}
            >
              {props.button.title}
            </Link>
          </div>
        )}
      </div>
    </TwoColContentSection>
  )
}

TwoColContent.propTypes = {
  className: PropTypes.string,
  titlesHideForTab: PropTypes.bool,
  centerTitlesTab: PropTypes.bool,
  fullWidthTab: PropTypes.bool,
  fullWidthLarge: PropTypes.bool,
  colTitleMaxWidth: PropTypes.bool,
  rowReverse: PropTypes.bool,
  textContent: PropTypes.string,
  subTitle: PropTypes.string,
  mainTitle: PropTypes.string,
  button: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    target: PropTypes.string,
  }),
}

TwoColContent.defaultProps = {
  titlesHideForTab: true,
  fullWidthTab: false,
  fullWidthLarge: false,
}

export default TwoColContent
