import React from "react"
import { PropTypes } from "prop-types"
import styled from "styled-components"
import tw from "tailwind.macro"
import BackgroundImage from "gatsby-background-image"
import classNames from "classnames"

import TwoColContent from "../TwoColContent"
import Leafs from "../../images/3-leafs.svg"

const TwoColSection = styled.section`
  ${tw`max-w-xl mx-auto px-18 md:px-40 lg:px-60 xxl:px-0`}

  .two-col-titles-xl {
    ${tw`hidden xl:block`}
  }

  .two-col-titles-sm-md-lg {
    ${tw`max-w-md mx-auto md:text-center xl:hidden`}

    h5 {
      ${tw`font-fb text-xs uppercase mb-8 text-primary`}
      letter-spacing: 3.6px;

      :before {
        content: "";
        background-image: url(${Leafs});
        ${tw`bg-contain bg-no-repeat w-12 h-12 block mb-4 md:mx-auto`}
      }
    }

    h2 {
      ${tw`font-fb text-2xl mb-0`}
      line-height: 44px;
      letter-spacing: -0.64px;

      @media (min-width: 768px) {
        font-size: 32px;
      }
    }
  }
`

const TwoColContainer = styled.div`
  ${tw`md:flex md:justify-center md:items-center md:pt-30`}
  flex-direction: ${props => (props.rowReverse ? "row-reverse" : "row")};

  .content-wrap {
    ${tw`md:pt-0 xl:pt-24`}
  }
`

const TwoColImage = styled.div`
  ${tw`mt-32 w-full md:w-2/4 md:mt-0`}

  .two-col-image-inner {
    padding: 35% 50%;
    height: 575px;
    ${tw`bg-no-repeat bg-cover bg-center md:h-full`}

    @media (min-width: 768px) {
      min-height: 468px;
    }

    @media (min-width: 1024px) {
      min-height: 388px;
      max-width: 320px;
      padding: 0;
    }

    &.right-image {
      margin-left: auto;
    }

    &.left-image {
      margin-right: auto;
    }

    @media (min-width: 1280px) {
      min-height: 575px;
      max-width: 100%;
    }

    &:after,
    &:before {
      border-radius: 110px 0;
    }
  }
`

const TwoColumn = ({ data }) => {
  return (
    <>
      {data?.map((item, index) => {
        const even = !!(index % 2)
        return (
          <TwoColSection
            key={index}
            className="two-col-section section-gap-sm xl:section-gap-xl"
          >
            <div className="two-col-titles-sm-md-lg">
              {item.subTitle && <h5>{item.subTitle}</h5>}
              {item.mainTitle && (
                <h2 className="xs:h2-sm lg:h2-lg">{item.mainTitle}</h2>
              )}
            </div>

            <TwoColContainer rowReverse={even}>
              <TwoColContent
                rowReverse={even}
                textContent={item.description}
                subTitle={item.subTitle}
                mainTitle={item.mainTitle}
                button={item.buttonTextLink}
              />
              <TwoColImage>
                <BackgroundImage
                  tag="div"
                  className={classNames(
                    "two-col-image-inner",
                    even ? "left-image" : "right-image"
                  )}
                  fluid={item.twoColImage.localFile.childImageSharp.fluid}
                />
              </TwoColImage>
            </TwoColContainer>
          </TwoColSection>
        )
      })}
    </>
  )
}

TwoColumn.propTypes = {
  rowReverse: PropTypes.bool,
  image: PropTypes.string,
  textContent: PropTypes.string,
  subTitle: PropTypes.string,
  mainTitle: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
}

TwoColumn.defaultProps = {
  rowReverse: false,
  image:
    "https://res.cloudinary.com/smashy-design-devs/image/upload/v1599460197/Public/Paramoam/two-col-image_tmooya.png",
  subTitle: "Our process",
  mainTitle: "Delivering same quality 24x7 for our partners in Asia Pacific",
}

export default TwoColumn
