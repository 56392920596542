import React from "react"
import SEO from "components/seo"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SubBanner from "../components/SubBanner"
import TwoColumn from "../components/TwoColumn"
import Testimonials from "../components/Testimonials"
import TitleIconColumns from "../components/TitleIconColumns"
import CTA from "../components/CTA"

export const query = graphql`
  query ProjectConsultancy {
    page: allWpPage(filter: { slug: { eq: "project-consultancy" } }) {
      edges {
        node {
          nodeType
          pageTitle: title
          acfSubPageBanner {
            sloganText: subPageBannerSloganText
            sloganTitle: subPageBannerSloganTitle
            subPageBannerImage {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          acfCta {
            ctaShow
            ctaText
            ctaLink1 {
              target
              title
              url
            }
            ctaLink2 {
              target
              title
              url
            }
          }
          acfTestimonials {
            testimonialShow
            testimonialCard {
              testimonialAuthorCompany
              testimonialAuthorName
              testimonialContent
              testimonialImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 325) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          acfProjectconsultancy {
            projectConsultancyIntroductionTitle
            projectConsultancyIntroductionTitleTagline
            projectConsultancyOption {
              projectConsultancyText
              projectConsultancyLink
              projectConsultancyIcon {
                localFile {
                  publicURL
                }
              }
            }
            pcTwoColumnRow {
              description: pcTwoColDescriptionContent
              mainTitle: pcTwoColMainTitle
              subTitle: pcTwoColSubTitle
              buttonTextLink: pcTwoColButtonTextLink {
                target
                title
                url
              }
              twoColImage: pcTwoColImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 680) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ...SEO
        }
      }
    }
  }
`

const ProjectConsultancyPage = ({ location, data }) => {
  const {
    seo,
    acfSubPageBanner,
    acfProjectconsultancy,
    acfCta,
    acfTestimonials,
  } = data.page.edges[0].node

  return (
    <Layout path={location.pathname}>
      <SEO data={seo} />

      <SubBanner
        mainTitle={acfSubPageBanner.sloganTitle}
        subTitle={acfSubPageBanner.sloganText}
        image={acfSubPageBanner?.subPageBannerImage?.localFile?.childImageSharp?.fluid}
      />

      <TitleIconColumns
        subTitle={
          acfProjectconsultancy.projectConsultancyIntroductionTitleTagline
        }
        mainTitle={acfProjectconsultancy.projectConsultancyIntroductionTitle}
        items={acfProjectconsultancy.projectConsultancyOption}
        iconFieldName="projectConsultancyIcon"
        textFieldName="projectConsultancyText"
        fieldLink="projectConsultancyLink"
      />

      <TwoColumn data={acfProjectconsultancy.pcTwoColumnRow} />

      {acfTestimonials.testimonialShow && (
        <Testimonials data={acfTestimonials.testimonialCard} />
      )}

      {acfCta.ctaShow && (
        <CTA
          text={acfCta.ctaText}
          buttonOne={acfCta.ctaLink1}
          buttonTwo={acfCta.ctaLink2}
        />
      )}
    </Layout>
  )
}

export default ProjectConsultancyPage
