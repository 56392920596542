import React from "react"
import { PropTypes } from "prop-types"
import styled from "styled-components"
import tw from "tailwind.macro"
import classNames from "classnames"
import Link from "../Link"

import bgleaf from "../../images/leaf.svg"

const IconListSection = styled.div`
  ${tw`max-w-xl mx-auto`}

  ul {
    ${tw`md:flex md:flex-wrap`}
  }
`

const Icon = styled.div`
  ${tw`mr-15 pl-8 bg-no-repeat bg-contain sm:mr-12`}
  background-image: url(${bgleaf});

  @media (min-width: 768px) {
    background-size: 70px 40px;
  }

  @media (min-width: 1280px) {
    background-size: 70px 47px;
  }

  img {
    min-width: ${props => (props.smallIcons ? "54px" : "64px")};
    min-height: ${props => (props.smallIcons ? "45px" : "64px")};
    max-width: 64px;
    max-height: 64px;

    @media (min-width: 1280px) {
      min-width: ${props => (props.smallIcons ? "60px" : "64px")};
      min-height: ${props => (props.smallIcons ? "60px" : "64px")};
    }
  }
`

const Items = styled.li`
  ${tw`flex items-center text-base md:text-lg md:w-3/6 xl:w-1/3 lg:text-xl md:pr-25 xl:pr-16`}

  p {
    ${tw`text-primary-dark font-medium text-xl leading-9`}
    letter-spacing: -0.4px;

    a {
      ${tw`text-xl`}

      :hover {
        ${tw`underline`}
      }
    }
  }

  .vertical & {
    ${tw`w-full`}
  }
`

const IconList = ({
  items,
  horizontal,
  smallIcons,
  className,
  iconFieldName = "icon",
  textFieldName = "title",
  fieldLink = "#",
}) => {
  return (
    <IconListSection
      className={classNames(
        "icon-list",
        smallIcons ? "xl:w-1/2" : "",
        horizontal ? "horizontal" : "vertical",
        className
      )}
    >
      <ul
        className={
          smallIcons
            ? "-mb-8 md:-mb-28 lg:-mb-20"
            : "-mb-30 md:-mb-28 lg:-mb-30"
        }
      >
        {items?.map((item, index) => (
          <Items
            key={index}
            className={
              smallIcons
                ? "mb-15 md:mb-28 lg:mb-20 xl:justify-start"
                : "mb-30 md:mb-28 lg:mb-30"
            }
          >
            <Icon smallIcons={smallIcons}>
              <img
                src={item[iconFieldName]?.localFile?.publicURL}
                alt={item.title}
              />
            </Icon>

            {item[fieldLink] ? (
              <p>
                <Link to={item[fieldLink]}>{item[textFieldName]}</Link>
              </p>
            ) : (
              <p>{item[textFieldName]}</p>
            )}
          </Items>
        ))}
      </ul>
    </IconListSection>
  )
}

IconList.propTypes = {
  horizontal: PropTypes.bool,
  smallIcons: PropTypes.bool,
  className: PropTypes.string,
}

IconList.defaultProps = {
  horizontal: true,
  smallIcons: false,
}

export default IconList
